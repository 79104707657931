.project-container {
  padding-top: 80px;
  margin-top: 80px;
  margin-bottom: 80px;
}
.tab {
  font-weight: 500px;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0s;
  border-bottom: 2px solid transparent;
}

.tab h5:hover {
  border-bottom: 2px solid dodgerblue;
  cursor: pointer;
}

.active {
  border-bottom: 2px solid dodgerblue;
}

.non-active {
  border-bottom: 2px solid transparent;
}

.project-card {
  margin-top: 80px;
}

.project-description h3 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 30px;
}

.project-description p {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 400;
}

.tag-info li p {
  font-weight: 500;
  color: dodgerblue;
  border-bottom: 1px solid dodgerblue;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0.3s;
}

.tag-info li p:hover {
  border-bottom: 1px solid transparent;
}
