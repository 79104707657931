.about-me-container {
  margin-top: 80px;
  margin-bottom: 80px;
  /* background-color: aliceblue; */
}
.about-me {
  padding-top: 80px;
}

.about-me-image {
  height: auto;
  width: 100%;
}

.about-me-description {
  float: left;
}

.about-me-description h5 {
  font-size: 16px;
  font-weight: 700;
  color: dodgerblue;
}

.about-me-description h3 {
  font-size: 34px;
  font-weight: bold;
  line-height: 44px;
}
.about-me-description p {
  font-size: 16px;
  line-height: 28px;
}

.single-info {
  margin: 0;
  padding: 0;
}

.single-info li {
  display: inline-block;
  /* margin-bottom: 6px; */
}

.single-info li p {
  font-size: 16px;
  margin-left: 12px;
}

li {
  list-style-type: none;
  outline: none;
}

.name-style {
  font-weight: 600;
  font-size: 17px;
  width: 75px;
}
