@import 'bootstrap/dist/css/bootstrap.min.css';
@import './css/hero.css';
@import './css/timeline.css';
@import './css/about-me.css';
@import './css/project.css';
@import './css/contact-bar.css';
@import './css/contact.css';
@import './css/header.css';

* {
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  box-shadow: none;
}
*:focus {
  outline: none !important;
  box-shadow: none !important;
}
